import React from "react"
import SocialMedia from "../SocialMedia"
import phone from "../../images/phone.png"

const TopHeader = ({ disabled }) => {
  return (
    <div className="top-header">
      <div className="container">
        <div className="top d-flex align-items-center">
          <SocialMedia
            color="dark-blue"
            facebookUrl="https://www.facebook.com/afdtoccitanie"
            linkedInUrl="https://www.linkedin.com/company/au-fil-des-toits"
            mail="agence.montpellier@afdtoccitanie.fr"
          />
          <img src={phone} alt="phone logo" />
          <span className="phone-number">
            <span className="font-weight-bold">04.11.95.05.67</span>
            <span className="font-weight-light ml-1">(Montpellier)</span>
          </span>
          <span className="phone-number">
            <span className="font-weight-bold">04.66.64.73.19</span>
            <span className="font-weight-light ml-1">(Nîmes)</span>
          </span>
          <span className="phone-number">
            <span className="font-weight-bold">04.67.77.84.55</span>
            <span className="font-weight-light ml-1">(Béziers)</span>
          </span>
          <a className="call btn-green ml-auto" href="/contact">
            Être rappelé
          </a>
        </div>
      </div>
    </div>
  )
}

export default TopHeader
