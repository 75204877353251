import React from "react"

import SocialMedia from "../components/SocialMedia"

import AFDTlogo from "../images/logo_afdt.png"
import RGElogo from "../images/logo_rge.png"
import EnvolLogo from "../images/logo_envol.png"

const Footer = () => (
  <footer className="bg-dark-blue font-white d-flex align-items-center">
    <div className="container">
      <div className="row h-100 text-center">
        <div className="col-lg-3 col-md-6 my-auto">
          <img src={AFDTlogo} alt="logo au fil du toit" className="img-fluid" />
        </div>
        <div className="col-lg-3 col-md-6 my-auto">
          <div className="mx-auto">
            <div className="address">
              <div>Montpellier : 04.11.95.05.67</div>
              <div>Nîmes : 04.66.64.73.19</div>
              <div>Béziers : 04.67.77.84.55</div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 my-auto">
          <div className="text-center">
            <div className="pb-2">Montpellier</div>
            <SocialMedia
              color="white"
              facebookUrl="https://www.facebook.com/afdtoccitanie"
              linkedInUrl="https://www.linkedin.com/company/au-fil-des-toits"
              mail="agence.montpellier@afdtoccitanie.fr"
            />
            <div className="pb-2 pt-3">Nîmes</div>
            <SocialMedia
              color="white"
              facebookUrl="https://www.facebook.com/AuFilDesToitsNimes"
              linkedInUrl="https://www.linkedin.com/company/au-fil-des-toits-nimes"
              mail="agence.nimes@afdtoccitanie.fr"
            />
            <div className="pb-2 pt-3">Béziers</div>
            <SocialMedia
              color="white"
              facebookUrl="https://www.facebook.com/aufildestoitsbeziers"
              linkedInUrl="https://www.linkedin.com/company/au-fil-des-toits-beziers"
              mail="agence.beziers@afdtoccitanie.fr"
            />
            <div className="copyright">
              <div>&copy; Tous droits réservés</div>
              <a href="/mentions-legales">Mentions légales</a>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 my-auto">
          <div className="logo-container">
            <img src={EnvolLogo} alt="logo Envol" />
            <img src={RGElogo} alt="logo RGE" />
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
