import React from "react"
import facebookW from "../images/layout/facebook_w.svg"
import facebookB from "../images/layout/facebook_b.svg"
import linkedinW from "../images/layout/linkedin_w.svg"
import linkedinB from "../images/layout/linkedin_b.svg"
import mailW from "../images/layout/mail_w.svg"
import mailB from "../images/layout/mail_b.svg"

const SocialMedia = ({ color, facebookUrl, linkedInUrl, mail }) => (
  <div className="social-media">
    <a href={facebookUrl} target="_blank" rel="noreferrer">
      <img
        src={color === "white" ? facebookW : facebookB}
        alt="logo facebook"
      />
    </a>

    <a href={linkedInUrl} target="_blank" rel="noreferrer">
      <img
        src={color === "white" ? linkedinW : linkedinB}
        alt="logo linkedIn"
      />
    </a>
    <a href={`mailto:${mail}`}>
      <img src={color === "white" ? mailW : mailB} alt="logo linkedIn" />
    </a>
  </div>
)

export default SocialMedia
